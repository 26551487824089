define('modules/winners/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.mobile.custom"], function (module, config, $, util, templates) {
    return new module({
        name: 'winners',
        selectors: '.winnersPage',
        remoteData: [config.urls.winners],

        routes: [{
            route: 'winners/(\\d+)',
            fn: function (hash) {
                var winnersId = hash.split('/')[1];
                console.log('winnersId: ' + winnersId);
                this.renderWinnersDetails(winnersId);
            },
            extra: true,
            delay: 300
        }, {
            route: 'winners',
            fn: 'closeDetails',
            extra: true
        }],

        config: {},

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = data;

            this.$container.find('.winnersContainer').html(templates.winnerspage({
                records: data
            }));

            this.flexCheck();
            this.attachEventHandlers();
        },

        onRender: function () {
            console.log('onRender');
            if (this['defferedRenderWinnersDetail']) {
                this.renderWinnersDetails(this['defferedRenderWinnersDetail']);
            }
        },

        methods: {

            renderWinnersDetails: function (winnersId) {
                if (!this.isReady()) {
                    this.defferedRenderWinnersDetail = winnersId;
                    return;
                }

                if (!this.winnersById) {
                    var winnersById = {};
                    $.each(this.data, $.proxy(function (index, item) {
                        item.originalIndex = index;
                        winnersById[item.id] = item;
                    }, this));
                    this.winnersById = winnersById;
                }
                var winnersItem = this.winnersById[winnersId];
                console.log('winnersItem: ' + winnersItem);

                if (winnersItem) {
                    if (!winnersItem.prevItem) {
                        var i, currentItem;
                        for (i = winnersItem.originalIndex - 1; i >= 0; i--) {
                            currentItem = this.data[i];
                            if (winnersItem.category === currentItem.category) {
                                winnersItem.prevItem = currentItem;
                                break;
                            }
                        }
                        if (!winnersItem.prevItem) {
                            for (i = this.data.length - 1; i > winnersItem.originalIndex; i--) {
                                currentItem = this.data[i];
                                if (winnersItem.category === currentItem.category) {
                                    winnersItem.prevItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }
                    if (!winnersItem.nextItem) {
                        for (i = winnersItem.originalIndex + 1; i < this.data.length; i++) {
                            currentItem = this.data[i];
                            if (winnersItem.category === currentItem.category) {
                                winnersItem.nextItem = currentItem;
                                break;
                            }
                        }
                        if (!winnersItem.nextItem) {
                            for (i = 0; i < winnersItem.originalIndex; i++) {
                                currentItem = this.data[i];
                                if (winnersItem.category === currentItem.category) {
                                    winnersItem.nextItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }

                    this.$container.closest('body').addClass('locked').find('.navbarWrap').addClass('hidden').hide();

                    this.$container.find('.winnersDetailContainer').html(templates.winnersDetailsModal({
                        item: winnersItem
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function () {
                        this.$container.find('.winnersDetailContainer').addClass('winners-details-opened');
                    }, this), 1000);
                }
            },

            closeDetails: function () {
                if (this.isReady()) {
                    var winnersDetailContainer = $('.winnersDetailContainer');
                    $('body').removeClass('locked');
                    winnersDetailContainer.removeClass('winners-details-opened');
                    setTimeout($.proxy(function () {
                        this.$container.find('.winnersDetailContainer').empty();
                    }, this), 1000);
                    $('.navbarWrap').removeClass('hidden').show();
                    this.$container.find('.carousel.slide').carousel('cycle');
                }
            },

            attachEventHandlers: function () {
                this.$container.on('click', '.winnersDetailContainer .modal-close', $.proxy(function (e) {
                    e.preventDefault();
                    window.history.pushState('winners', 'Winners', '#winners');
                    this.closeDetails();
                }, this));
            },

            flexCheck: function () {
                if ($('.winnersGridFlex .winnersGridPromoBox').length === 2) {
                    $('.winnersGridFlex').addClass('flex-two');
                }
            }
        }

    });
});

