define('hooks',['jquery', 'app/config', 'app/util', 'app/event-bus', 'templates', 'jquery.livequery'], function ($, config, util, eventBus, templates) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");

            $('body').on('click', '.retire-modal-trigger', function (e) {
                e.preventDefault();
                $('.retire-modal').addClass('visible');
                $('body').addClass('modal-visible');
            });

            $('.close-retire-modal').on('click', function () {
                $('.retire-modal').removeClass('visible');
                $('body').removeClass('modal-visible');
            });

            $(document).on('click', '.navbar-brand.scrollToTop', function(e) {
                e.preventDefault();

                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
                $(this).unbind('click');
            });

            $('.navbar-share-toggle').on('mouseenter', function () {
                var self = $(this);
                self.addClass('expanded');
                self.siblings('.social-links-inner').addClass('expanded');
            });

            $(".social-links-wrap").on('mouseleave', function () {
                var self = $(this);
                $('.navbar-share-toggle').removeClass('expanded');
                self.find('.social-links-inner').removeClass('expanded');
            });

            $('.navbar-share-toggle').on('click', function () {
                var self = $(this);

                self.siblings('.social-links-inner').toggleClass('expanded');
            });

            $(document).on('click','.download-dropdown-handle',function() {
                var self = $(this);

                self.closest('.download-dropdown').toggleClass('dropdown-opened');
                self.siblings('.download-dropdown-list').slideToggle(300);
            });

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {

                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

